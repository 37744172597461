<template>
  <div class="recharge">
    <!-- 导航栏 -->
    <van-nav-bar :title="$t('newAdd.newNavTitle')" :border="false">
      <template #right>
        <div class="record_bar" @click="$router.push('/ThirdRechargeList')">
          <i class="iconfont icon-lishijilu"></i>
        </div>
      </template>
    </van-nav-bar>

    <div class="userInfo_bar" :class="{ flex_reverse: lan === 'ar' }">
      <Avatar :src="userInfo.userimg" size="70" class="avatar" :style="lan === 'ar' && { marginLeft: 0, marginRight: 'calc(18rem / 16)' }
      " />
      <div class="right_bar" :class="{ text_end: lan === 'ar' }" :style="lan === 'ar' && { marginLeft: 0, marginRight: 'calc(12rem / 16)' }
      ">
        <div class="nickname">{{ userInfo.username }}</div>
        <div class="id">
          <span class="label"> {{ "label.id" | lan }}:&nbsp; </span>
          <span class="value">{{ userInfo.id || "" }}</span>
        </div>
        <div class="level_bar">
          <span class="label" v-if="lan !== 'ar'">
            {{ "label.level" | lan }}:&nbsp;
          </span>
          <span class="value">{{ userInfo.rank_title }}</span>
          <span class="label" v-if="lan === 'ar'">
            &nbsp;:{{ "label.level" | lan }}
          </span>
        </div>
        <div class="balance_bar">
          <span class="label">{{ $t("Home.WithdrawSu.Balance") }}:&nbsp;</span>
          <span class="value">{{ $money(userInfo.money, false) }}</span>
        </div>
      </div>
    </div>

    <!-- 商品信息 -->
    <div class="goodInfo_wrap" v-if="goodInfo.id" :class="{ flex_reverse: lan === 'ar' }">
      <img :src="goodInfo.goods_image" alt="" class="good_pic" />
      <div class="other_info" :class="{ text_end: lan === 'ar' }">
        <div class="good_title">{{ goodInfo.goods_name }}</div>
        <div class="good_price">
          <span class="label">{{ $t("label.price") }}:&nbsp;</span>
          <span class="value">{{ number }}</span>
        </div>
      </div>
    </div>

    <!-- 文本域 -->
    <div class="url_textarea_wrap">
      <Field v-model="urlInput" rows="3" autosize type="textarea" :placeholder="$t('urlParsePlaceholder')"
        class="textarea" />
      <div class="submit_btn" @click="Identify">
        {{ $t("btn.Identify") }}</div>
    </div>

    <div class="form_bar">
      <!-- <van-field
        v-if="isTabBarLinkNotShowShopMoney"
        center
        readonly
        v-model="number"
        type="number"
        class="number readonly"
        :label="`${$t('newAdd.shopMoney')}\n`"
        :placeholder="$t('newAdd.shopMoney')"
        :class="{ input_ar: lan === 'ar' }"
      /> -->
      <van-field center class="number" v-model="newForm.money" type="number" :label="`${$t('newAdd.actualMoney')}\n`"
        :placeholder="$t('newAdd.actualMoney')" :class="{ input_ar: lan === 'ar' }" />
      <van-tabs v-model="useNetWorkActive" swipeable class="tabs" line-height="2px" line-width="15px"
        @click="handleUseNetWorkChange" background="transparent" color="var(--themes)" title-active-color="#000"
        title-inactive-color="#fff">
        <!-- <van-tab v-for="(item, index) in useNetWorkList" :key="index" :title="item.title">
          <span slot="title">{{ item.title }}</span>
        </van-tab> -->
      </van-tabs>
      <div class="textarea_wrap">
        <van-field v-model="newForm.hash" rows="5" autosize type="textarea" :placeholder="$t('hashPlaceholder')"
          class="textarea" />
        <van-button size="small" color="var(--newBtnBg)" class="btn" round @click="handleCopy(newForm.hash)">{{
      $t("newAdd.paste") }}</van-button>
      </div>
    </div>

    <!-- <van-field
      center
      readonly
      v-model="userInfo.id"
      label="ID"
      :class="{ input_ar: lan === 'ar' }"
    /> -->

    <!-- <van-field
      v-if="isTabBarLinkNotShowShopMoney"
      center
      readonly
      v-model="number"
      type="number"
      :label="`${$t('newAdd.shopMoney')}\n`"
      :placeholder="$t('newAdd.shopMoney')"
      :class="{ input_ar: lan === 'ar' }"
    />

    <van-field
      center
      class="number"
      v-model="newForm.money"
      type="number"
      :label="`${$t('newAdd.actualMoney')}\n`"
      :placeholder="$t('newAdd.actualMoney')"
      :class="{ input_ar: lan === 'ar' }"
    />

    <van-field
      center
      class="number"
      readonly
      v-model="newForm.netWork"
      :label="$t(`NetWork`)"
      :class="{ input_ar: lan === 'ar' }"
    />
    <van-tabs
      v-model="useNetWorkActive"
      swipeable
      class="tabs"
      line-height="2px"
      line-width="15px"
      @click="handleUseNetWorkChange"
    >
      <van-tab
        v-for="(item, index) in useNetWorkList"
        :key="index"
        :title="item.title"
      >
        <span slot="title">{{ item.title }}</span>
      </van-tab>
    </van-tabs> -->

    <!-- <van-field
      center
      class="number"
      v-model="newForm.hash"
      :label="`Hash`"
      :placeholder="'Hash'"
    >
      <template #button>
        <van-button
          size="small"
          color="#fff"
          @click="handleCopy(newForm.hash)"
          >{{ $t("newAdd.paste") }}</van-button
        >
      </template>
    </van-field> -->
    <!-- <div class="textarea_wrap">
      <van-field
        v-model="newForm.hash"
        rows="3"
        autosize
        type="textarea"
        :placeholder="$t('hashPlaceholder')"
        class="textarea"
      />
      <van-button
        size="small"
        color="#fff"
        class="btn"
        @click="handleCopy(newForm.hash)"
        >{{ $t("newAdd.paste") }}</van-button
      >
    </div> -->

    <button class="commit-btn" @click="handleCommit">
      {{ $t("newAdd.commitBtnText") }}
    </button>
    <div class="empty"></div>
  </div>
</template>

<script>
import {
  GET_CONFIG_INFO,
  GET_RECHARGE_LIST,
  getUserInfoNew,
  RECHARGE_COMMIT,
} from "@/api";
import Cookies from "js-cookie";
import { getUserInfo } from "@/utils/tools";
import verConfig from "@/utils/verConfig";
import Avatar from "@/components/avatar";
import { domain, $get } from "@/utils/request";
import {
  NavBar,
  cell,
  icon,
  field,
  Dialog,
  Toast,
  Grid,
  GridItem,
  tabs,
  tab,
  Button,
  Field,
} from "vant";

export default {
  components: {
    [NavBar.name]: NavBar,
    [cell.name]: cell,
    [icon.name]: icon,
    [field.name]: field,
    [Toast.name]: Toast,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [tabs.name]: tabs,
    [tab.name]: tab,
    [Dialog.Component.name]: Dialog.Component,
    [Button.name]: Button,
    Avatar,
    Field,
  },
  data() {
    return {
      verConfig,
      countList: [
        "100",
        "200",
        "500",
        "1000",
        "5000",
        "10000",
        "30000",
        "50000",
      ],
      account: "",
      number: "",
      active: 0,
      tabPages: [],
      showTutorial: false,
      count: 1,
      serviceURL: "",
      country: Cookies.get("language"),
      goodInfo: {},
      rechargeType: "",
      userInfo: {},
      useNetWorkList: [],
      useNetWorkActive: 0,
      newForm: {
        money: 0,
        netWork: "",
        hash: "",
      },
      rechargePath: this.verConfig.pathNewRecharge
        ? "third-recharge-n"
        : "new-recharge",
      isTabBarLinkNotShowShopMoney: false,
      urlInput: "",
    };
  },
  created() {
    getUserInfoNew();
    this.getRechargeList();
    // this.getLists()
    // this.getService()
    // console.log(Cookies.get('language'));
  },
  mounted() {
    this.userInfo = getUserInfo();
    this.account = getUserInfo().userphone;
    const data = localStorage.getItem("rechargeGoodDataInfo")
      ? JSON.parse(localStorage.getItem("rechargeGoodDataInfo"))
      : this.$route.query;
    // this.goodInfo = JSON.parse(this.$route.query.data);
    this.goodInfo =
      typeof data.data === "string" ? JSON.parse(data.data) : data.data || {};
    this.rechargeType = data.type;
    if (this.goodInfo) {
      this.number = this.goodInfo.goods_price;
      this.isTabBarLinkNotShowShopMoney = true;
    }
  },
  methods: {
    goodInfoInit(data) {
      // this.rechargeType = data.type || "third";
      // if (!data.goodInfo) return;
      // this.number = data.goods_price;
      // this.isTabBarLinkNotShowShopMoney = true;
    },
    async Identify() {
      if (!this.urlInput) return;
      Toast.loading({ duration: 0 });
      try {
        const res = await $get("/third/parse/url", { url: this.urlInput });
        Toast.clear();
        const { ret, data, msg } = res.data;
        if (ret === 1) {
          this.goodInfo = data;
          this.rechargeType = "third";
          this.number = data.goods_price;
          this.newForm.money = data.goods_price;
          this.isTabBarLinkNotShowShopMoney = true;
          return;
        }
        Toast(msg);
      } catch (error) {
        Toast.clear();
      }
    },
    handleCommit() {
      const { hash, money } = this.newForm;
      if (!hash) return Toast(this.$t("newAdd.commitHint"));
      this.paySubmit(this.useNetWorkList[this.useNetWorkActive]);
    },
    handleUseNetWorkChange(name, title) {
      this.newForm.netWork = title;
    },
    async handleCopy(copyText) {
      // if (!copyText) return Toast('Copy Fail')
      // this.$copyText(copyText).then((e) => {
      //   Toast('Copy successfully')
      // })
      let pasteStr = await navigator.clipboard.readText();
      this.newForm.hash = pasteStr;
    },
    // add
    goback() {
      history.back();
    },
    getRechargeList() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_RECHARGE_LIST()
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 1) {
            Toast.clear();
            this.tabPages = r.data.data;
            this.useNetWorkList = this.tabPages.find(
              (_) => _.type === "usdt"
            )?.lists;
            this.newForm.netWork =
              this.useNetWorkList[this.useNetWorkActive].title;
          }
        })
        .catch((e) => {
          Toast.clear();
        });
    },
    // 将点击项的值绑定给充值金额框
    addClass(value) {
      this.number = value;
    },

    // 获取充值方式的数据
    async getLists() {
      const {
        data: { data, ret },
      } = await request({
        method: "get",
        url: "pay/lists",
      });
      if (ret === 1) {
        this.tabPages = data;
      }
    },

    // async getService () {
    //   const { data: { data, ret } } = await request({
    //     method: 'get',
    //     url: 'other/config',
    //     params: {
    //       act: 'system',
    //       refresh: this.isLoading === true ? 1 : 0
    //     }
    //   })
    //   if (ret === 1) {
    //     this.serviceURL = data.service_url
    //   }
    // },
    // 教程
    changeImg() {
      this.count += 1;
      if (this.count === 6) {
        this.showTutorial = false;
        this.count = 1;
      }
    },

    handlerService() {
      //   Toast({
      //     message: 'Try again later'
      //   })
      window.location.href = this.serviceURL;
    },

    paySubmit(item) {
      if (!this.goodInfo.id) {
        return Toast(this.$t("thirdRecharge.hint.noGoods"));
      }
      const { hash, money } = this.newForm;
      const { id } = item;
      let params = {
        id,
        money,
        hash,
        account: this.account,
        isbefore: 1,
      };
      if (this.isTabBarLinkNotShowShopMoney) {
        params.third_id =
          this.rechargeType !== "third"
            ? Number(this.goodInfo.id) + 100000000
            : this.goodInfo.id;
      }

      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      RECHARGE_COMMIT(params)
        .then((r) => {
          Toast.clear();

          const { ret, msg } = r.data;

          if (!ret) return Toast(msg);

          // return this.$router.push("recharge-list");
          return this.$router.push("thirdRechargeList");

          if (r.data.ret === 2) {
            Toast.clear();
            window.location.href = r.data.data.url;
          } else if (r.data.ret === 1) {
            if (r.data.data.bank.indexOf("qrcode") != -1) {
              r.data.data.step = item.step;
              r.data.data.id = item.id;
              this.$router.push({
                name: "qrPay",
                params: {
                  payData: r.data.data,
                },
              });
            }
          } else if (r.data.ret === 3) {
            if (r.data.data.bank.indexOf("bank-BANK") != -1) {
              r.data.data.step = item.step;
              r.data.data.id = item.id;
              this.$router.push({
                name: "bankPay",
                params: {
                  payData: r.data.data,
                },
              });
            }
          } else {
            // Toast('System exception, please try again later')
            Toast(r.data.msg);
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
  },
  beforeDestroy() {
    localStorage.removeItem("rechargeGoodDataInfo");
  },
  computed: {
    addCount() {
      return this.count;
    },
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
@Bc: var(--nav-background);
@comMargin: calc(12rem / 16);
@comRadius: calc(10rem / 16);

.recharge {
  position: relative;
  height: 100%;
  min-height: calc(100vh - 50px);
  background-color: @Bc;

  /deep/ .van-tabs__line {
    background-color: @Color;
  }

  .goodInfo_wrap {
    margin: @comMargin;
    padding: calc(12rem / 16);
    background: #fff;
    border-radius: @comRadius;
    display: flex;

    .good_pic {
      width: 120px;
      height: 120px;
      // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      border-radius: 4px;
    }

    .other_info {
      margin-left: 20px;

      .good_price {
        margin-top: calc(12rem / 16);

        .label {
          color: #999;
          font-size: calc(14rem / 16);
        }

        .value {
          color: var(--themes);
        }
      }
    }
  }

  .van-nav-bar {
    background-color: var(--nav-background);

    .record_bar {
      text-align: center;
      color: var(--btnsizeColor);
      background: var(--themes);
      width: calc(40rem / 16);
      height: calc(40rem / 16);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      &:active {
        opacity: 0.8;
      }

      .iconfont {
        font-size: calc(40rem / 16);
      }

      .text {
        font-size: calc(15rem / 16);
      }
    }
  }

  .userInfo_bar {
    display: flex;
    align-items: center;
    margin: @comMargin;
    background: #fff;
    border-radius: @comRadius;
    padding: calc(16rem / 16) 0;

    .avatar {
      margin-left: calc(18rem / 16);
    }

    .right_bar {
      margin-left: calc(12rem / 16);

      >div {
        margin-top: calc(6rem / 16);

        &:nth-child(1) {
          margin-top: 0;
        }

        .label {
          color: #666;
          font-size: calc(15rem / 16);
        }

        .value {
          color: #000;
        }
      }
    }
  }

  .url_textarea_wrap {
    // width: 90%;
    margin: @comMargin;

    .textarea {
      border-radius: 6px;
      background: #fff;
      border: 1px solid #333;
    }

    /deep/ textarea {
      color: #4f5860;
    }

    .submit_btn {
      @Color: var(--newBtnBg);
      text-align: center;
      // background: linear-gradient(to right, #0dccff, #3c7bca);
      background: @Color;
      margin: 0 auto;
      border: 1px solid @Color;
      color: var(--btnsizeColor);
      padding: 2.667vw 0;
      // border-radius: 999px;
      border-radius: 6px;
      margin-top: 20px;
    }
  }

  .van-field {
    margin: calc(6rem / 16) 0;

    /deep/.van-field__label {
      width: calc(177rem / 16) !important;
    }

    /deep/.van-field__body {
      input {
        text-align: center;
      }
    }
  }

  .number {
    /deep/.van-field__body {
      input {
        text-align: center;
        color: var(--themes);
        margin: 0 3px;
      }
    }
  }

  .grid {
    margin: calc(7.5rem / 16) 0;

    .grid_item {
      /deep/ .van-grid-item__content {
        padding: 0;

        div {
          width: 100%;
          height: calc(41rem / 16);
          font-size: calc(13rem / 16);
          line-height: calc(45rem / 16);
          text-align: center;
          color: @Color;
          background-color: #ffffff;
        }

        .active {
          color: #ffffff;
          background-color: @Color;
        }
      }
    }
  }

  .van-cell {
    background-color: transparent;
  }

  .list-title-text {
    font-size: calc(13rem / 16);
  }

  .list-title-rtext {
    color: #a87cf3;
    font-size: calc(13rem / 16);
    text-decoration: underline;
  }
  .input_ar{
    font-size: calc(16rem / 16);
  }
  .tabs {

    // /deep/ .van-tabs__nav {
    //   background: #ecf3fb;
    // }
    .mode {
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      margin-top: calc(7.5rem / 16);
      padding: calc(7.5rem / 16);
      background-color: #fff;
    }

    .step {
      font-size: calc(12rem / 16);

      h5 {
        margin: 0 0 calc(7.5rem / 16) 0;
      }

      .desc_wrap {
        display: flex;
        justify-content: space-between;

        img {
          height: 30px;
          min-width: 30px;
          margin-right: 20px;
        }
      }

      p {
        width: 80%;
      }
    }

    .tabbar-letter {
      position: absolute;
      top: 0;
      right: 0;
      width: calc(32.5rem / 16);
      height: calc(32.5rem / 16);
    }

    .step-image {
      width: calc(50rem / 16);
      height: calc(50rem / 16);
      margin-right: calc(10rem / 16);
    }

    .tips {
      text-align: center;
      margin-top: calc(45rem / 16);
      font-size: calc(10rem / 16);
    }
  }

  .tutorial {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100%;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.backimg {
  width: 20px;
  height: 20px;
}

.commit-btn {
  // width: 85%;
  width: calc(100% - @comMargin * 2);
  border-radius: 6px;
  height: calc(44rem / 16);
  font-size: calc(15rem / 16);
  background: var(--newBtnBg);
  color: var(--btnsizeColor);
  border: none;
  padding: calc(10rem / 16) calc(26rem / 16);
  display: block;
  margin: calc(20rem / 16) auto 0;
}

/deep/ .van-button {
  // background: var(--nav-background) !important;
  border: none;
  color: var(--btnsizeColor);
  font-size: calc(15rem / 16);
}

// .textarea_wrap {
//   width: 90%;
//   margin: 20px auto;
//   // background: #f5f5f5;
//   border-radius: 6px;
//   position: relative;
//   border: 1px solid #f5f5f5;
//   box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

//   .textarea {
//     border-radius: 6px;
//   }
//   /deep/ .van-cell {
//     &::after {
//       border: none;
//     }
//   }

//   /deep/ textarea {
//     // color: #4f5860;
//     color: var(--theme);
//   }
//   .btn {
//     position: absolute;
//     right: calc(8rem / 16);
//     bottom: calc(8rem / 16);
//   }
// }

.form_bar {
  // margin: @comMargin;
  // border-radius: @comRadius;
  // background: var(--theme);
  padding: @comMargin;
  @contentRadius: calc(8rem / 16);

  .number {
    background: #fff;
    border-radius: @contentRadius;
    margin: 0;
    margin-top: @comMargin;

    &:nth-child(1) {
      margin-top: 0;
    }

    /deep/.van-field__label {
      width: fit-content !important;
    }
    /deep/ input {
      border-bottom: 1px solid var(--themes);
    }
  }
  /deep/ .van-tabs__wrap{
    height: 0;
  }

  .readonly {
    /deep/ input {
      border-color: #eee;
      color: #666 !important;
    }
  }

  .tabs {
    margin: calc(@comMargin - 5px) 0;

    /deep/ .van-tabs__line {
      display: none;
    }

    /deep/ .van-tab {
      .van-tab__text {
        border: 1px solid var(--tabColor, #333);
        color: var(--tabColor, #333);
        width: 100%;
        text-align: center;
        line-height: calc(34rem / 16);
        border-radius: 6px;
      }
    }

    /deep/ .van-tab--active {
      .van-tab__text {
        // background: #ffd600;
        background: var(--newBtnBg);
        border: 1px solid var(--newBtnBg);
        // border: none;
        color: var(--tabSizeColor, #fff);
        // border-radius: 4px;
      }
    }
  }

  .textarea_wrap {
    width: calc(100% - 2px);
    // margin: @comMargin;
    background: #fff;
    border-radius: 6px;
    position: relative;
    border: 1px solid #333;
    // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    .textarea {
      border-radius: 6px;
    }

    /deep/ .van-cell {
      &::after {
        border: none;
      }
    }

    /deep/ textarea {
      // color: #4f5860;
      color: var(--themes);
    }

    .btn {
      position: absolute;
      right: calc(8rem / 16);
      bottom: calc(8rem / 16);
      @size: calc(50rem / 16);
      width: @size;
      height: @size;
      border-radius: 50%;
      // color: #000 !important;
    }
  }
}

.empty {
  height: calc(50rem / 16);
}
</style>
